import * as React from "react"
import { graphql } from "gatsby"
import { Space, Container, Section, FlexList, Subhead, Logo } from "./ui"

export function LogoItem(props) {
  if (!props.image) return false

  return <Logo alt={props.alt} style={{ filter: "invert(0%)" }} image={props.image} size="medium" />
}

export default function LogoList(props) {
  return (
    <Section paddingY={4}>
      <Container 
        width="narrow"
        // className="drawSquare"
        style={{ 
          // borderRadius: "15px",
          // border: '1px solid black',
          // backgroundColor: "rgba(137,136,152,.5)",
          // padding: "24px",
          textAlign: "center",
          // color: "white",
         }}>
        {props.text && (
          <Subhead as="h2">
            {props.text}
          </Subhead>
        )}
        <Space size={4} />
        <FlexList gap={4} variant="center">
          {props.logos.map(
            (logo) =>
              logo && (
                <li key={logo.id}>
                  <LogoItem {...logo} />
                </li>
              )
          )}
        </FlexList>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageLogoListContent on HomepageLogoList {
    id
    text
    logos {
      id
      alt
      image {
        id
        gatsbyImageData
        alt
      }
    }
  }
`
