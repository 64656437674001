import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  Text,
  Flex,
  Box,
  SuperHeading
} from "./ui"
import * as styles from "./about-hero.css.ts"
import {
  imageToBlackWhite,
} from "./hero.css.ts"

export default function AboutHero(props) {
  console.log(props)
  return (
    <Section>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="half">
            {props.image && (
              <GatsbyImage
                alt={props.image.alt}
                image={getImage(props.image)}
                className={imageToBlackWhite}
                style={{
                  height: "400px",
                  width: "500px",
                  left: "150px",
                  zIndex: "-2"
                }}
              />
            )}
          </Box>
          <Box 
            width="half" 
            style={{ 
              borderRadius: "15px",
              border: "1px solid black",
              backgroundColor: "rgba(137,136,152,.5)",
              padding: "24px",
              color: "white",
             }}>
            <SuperHeading className={styles.aboutHeroHeader}>
              {props.heading}
            </SuperHeading>
            {props.text && (
              <Text className={styles.aboutHeroText}>{props.text}</Text>
            )}
          </Box>
        </Flex>
      </Container>
      <Container style={{ marginTop: "24px" }}>
        <Box
          width="half"
          style={{ 
            borderRadius: "15px",
            border: "1px solid black",
            backgroundColor: "rgba(137,136,152,.5)",
            padding: "24px",
            color: "white",
          }}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.page.html,
            }}
          />
        </Box>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment AboutHeroContent on AboutHero {
    id
    heading
    text
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
