import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
  FlexList,
  Text,
  Kicker,
  Heading,
  Subhead,
  Box,
  Icon,
  LinkList,
  Flex
} from "./ui"

function Product(props) {
  return (
    <Box center>
      {props.image && (
        <Icon alt={props.image.alt} image={props.image} size="large" />
      )}
      <Subhead>{props.heading}</Subhead>
      <Text>{props.text}</Text>
      <LinkList links={props.links} />
    </Box>
  )
}

export default function ProductList(props) {
  return (
    <Section>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="half">
            <Heading>
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.heading}
            </Heading>
            {props.text && <Text>{props.text}</Text>}
          </Box>
          <Box 
            width="half" 
            style={{ 
              width: '140%',
              // borderRadius: "15px",
              // border: "1px solid black",
              // backgroundColor: "rgba(137,136,152,.5)",
              // padding: "24px",
              // color: "white",
             }}>
            <FlexList gap={4} variant="responsive">
              {props.content.map((product) => (
                <li key={product.id}>
                  <Product {...product} />
                </li>
              ))}
            </FlexList>
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageProductListContent on HomepageProductList {
    id
    kicker
    heading
    text
    content {
      id
      heading
      text
      image {
        alt
        id
        gatsbyImageData
      }
      links {
        id
        href
        text
      }
    }
  }
`
