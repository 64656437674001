import * as React from "react"
import { Container, Box, Heading } from "./ui"

export default function Page(props) {
  return (
    <Box paddingY={5}>
      <Container width="narrow">
        <Heading as="h1">{props.title}</Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: props.html,
          }} />
      </Container>
    </Box>
  )
}
