import React from 'react'

import axios from 'axios'

import CircularProgress from '@mui/material//CircularProgress'
import Button from '@mui/material//Button'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function CircularIndeterminate(props) {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const stripe_payment_methods = [
    'card'
  ]

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)

    const stripeObject = {
      payment_method_types: stripe_payment_methods,
      line_items: [{
        price_data: {
          product_data: {
            name: "Software development downpayment",
            description: `
              (1) Backend: ${props.product.backend}, 
              (2) Frontend: ${props.product.frontend}, 
              (3) Hosting, development and deploy platform: ${props.product.hosting}, 
              (4) Include a blog: ${props.product.blog ? "Yes" : "No"}, 
              (5) Include legal texts (privacy policy, terms conditions): ${props.product.legal ? "Yes" : "No"}, 
              (6) Develop a brandstyle: ${props.product.brandstyle ? "Yes" : "No"}, 
              (7) Include functionality for a newsletter: ${props.product.newsletter ? "Yes" : "No"}
            `,
            metadata: {
              'backend': props.product.backend,
              'frontend': props.product.frontend,
              'hosting': props.product.hosting,
              'blog': props.product.blog,
              'legal': props.product.legal,
              'brandstyle': props.product.brandstyle,
              'newsletter': props.product.newsletter
            }
          },
          currency: 'usd',
          unit_amount: 25000,
        },
        quantity: 1,
      }],
      mode: 'payment',
      success_url: `https://www.shoppingstreet.io/thank-you/`,
      cancel_url: `https://www.shoppingstreet.io/canceled/`,
    }

    try {
      const response = await axios.post('/.netlify/functions/stripeSession', stripeObject)
      if(response.data.url){
        window.location.href = response.data.url
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <div 
      style={{
        display: 'flex',
        float: 'right',
        '& > * + *': {
          marginLeft: '12px',
        },
      }}>
      {loading &&
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            margin: '6px',
          }}
          startIcon={<CircularProgress color="secondary" size={22} />}>
          {t("Proceeding to checkout...")}
        </Button>
      }
      {!loading &&
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            margin: '6px',
          }}
          startIcon={<ExitToAppIcon />}
          onClick={redirectToCheckout}>
          {t("Checkout and get this thing started!")}
        </Button>
      }
    </div>
  );
}
