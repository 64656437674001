import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import InformationBlock from '../product-config-question-information'

export default function SwipeableTemporaryDrawer(props) {
  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={'right'}
          open={props.open}
          onClose={props.toggleDrawer(false)}
          onOpen={props.toggleDrawer(true)}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              width: '80vw',
            },
            [theme.breakpoints.up('md')]: {
              width: '50vw',
            },
            [theme.breakpoints.up('lg')]: {
              width: '40vw',
            }
          })}>
          <InformationBlock title={props.title} html={props.html} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
