import * as React from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function VerticalToggleButtons(props) {
  const handleChange = (event, value) => {
    props.setProduct({...props.product, [props.type]: value});
  };

  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={props.product[props.type]}
      sx={{
        width: '100%'
      }}
      exclusive
      onChange={handleChange}>
      {props.options.map((option, index) => {
        const image = getImage(option.image)

        return (
          <HtmlTooltip
            followCursor
            key={option.id}
            title={
              <React.Fragment>
                <div
                  dangerouslySetInnerHTML={{
                    __html: option.html,
                  }}
                />
              </React.Fragment>
            }>
            <ToggleButton key={option.id} value={option.heading} aria-label={option.heading}>
              <Grid container>
                <Grid item sm={9} xs={12}>
                  <Stack spacing={0} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6">{option.heading}</Typography>
                    <Typography variant="caption">{option.subhead}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={3} xs={12}>
                  {option.image &&
                    <GatsbyImage
                      alt={option.image.alt}
                      image={image}
                      style={{ height: '63px', float: 'right' }}
                      imgStyle={{ height: '63px', objectFit: 'contain' }} />
                  }
                </Grid>
              </Grid>
            </ToggleButton>
          </HtmlTooltip>
        )
      })}
    </ToggleButtonGroup>
  );
}

