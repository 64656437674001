import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function TemplateCards({ card }) {

  return (
    <Card 
      sx={{ 
        maxWidth: '100%', 
        height: '100%', 
        borderRadius: '25px',
        border: "1px solid black",
        backgroundColor: "rgba(137,136,152,.5)",
      }}>
      <CardHeader
        title={card.heading}
        subheader={card.subhead} />
      <CardContent>
        <div dangerouslySetInnerHTML={{ __html: card.html }} />
      </CardContent>
    </Card>
  );
}
