import * as React from "react"
import { graphql } from "gatsby"
import { Container, Section, Box, Heading, Subhead } from "./ui"

import PricingCards from './material/pricingCards'
import TextExpand from './custom/textExpand'

export default function PricingCardList(props) {
  return (
    <Section>
      <Container>
        <Box width="full">
          <Heading as="h1">
            <TextExpand text={props.heading} />
          </Heading>
          <Subhead as="h2">{props.subhead}</Subhead>
        </Box>
      </Container>
      <Container>
        <PricingCards content={props.content} />
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment PricingCardListContent on PricingCardList {
    id
    heading
    subhead
    content {
      id
      heading
      subhead
      html
    }
  }
`
