import * as React from "react"
import { graphql } from "gatsby"
import { Container, Section, Box, Heading, Subhead } from "./ui"

import DemoCards from './material/demoCards'
import TextExpand from './custom/textExpand'

export default function DemoTemplateList(props) {
  return (
    <Section>
      <Container>
        <Box width="full">
          <Heading as="h1">
            <TextExpand text={props.heading} />
          </Heading>
          <Subhead as="h2">{props.subhead}</Subhead>
        </Box>
      </Container>
      <Container>
        <DemoCards content={props.content} />
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment DemoTemplateListContent on DemoTemplateList {
    id
    heading
    subhead
    content {
      id
      heading
      links {
        href
        id
        text
      }
      subhead
      image {
        alt
        gatsbyImageData
      }
      logos {
        alt
        id
        image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`
