import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Kicker,
  Heading,
  Section,
  Flex,
  Box,
  Subhead,
  Text,
  Link,
} from "./ui"

import Question from "./product-config-question"
import CheckBoxQuestion from "./material/checkBoxes"

import Drawer from "./material/informationDrawer"
import CheckoutButton from "./material/checkOutButton"

import TextExpand from './custom/textExpand'

export default function ProductList(props) {
  const [product, setProduct] = React.useState({
    backend: "Shopify",
    frontend: "Gatsbyjs",
    hosting: "Netlify",
    blog: false,
    legal: false,
    brandstyle: true,
    newsletter: false
  })
  const [drawer, setDrawer] = React.useState(false);
  const [drawerHtml, setDrawerHtml] = React.useState();

  const toggleDrawer = open => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };
  
  const firstQuestions = props.content.slice(0, props.content.length - 2)
  const secondLastQuestion = props.content.slice(props.content.length - 2, props.content.length -1)[0]
  const lastQuestion = props.content[props.content.length - 1]

  return (
    <Container width="fullbleed">
        <Box 
          style={{ 
            // border: "1px solid black",
            // backgroundColor: "rgba(137,136,152,.5)",
            // padding: "24px",
            // color: "white",
          }} 
          radius="large">
          <Box center paddingY={5}>
            <Heading id="get-started">
              {props.subhead && 
                <Kicker>{props.subhead}</Kicker>
              }
              <TextExpand text={props.heading} />
            </Heading>
            {/* {props.text && <Text>{props.text}</Text>} */}
          </Box>
          {firstQuestions.map((question, i) => (
            <Question 
              key={question.id} 
              {...question} 
              flip={i % 2} 
              product={product} 
              setProduct={setProduct} />
          ))}
          {secondLastQuestion && 
            <Section padding={4}>
              <Container>
                <Flex gap={4} variant="responsive">
                  <Box width="half" order={1}>
                    <CheckBoxQuestion 
                      booleanQuestions={props.questions}
                      product={product} 
                      setProduct={setProduct} />
                  </Box>
                  <Box width="half">
                    <Subhead 
                      onClick={() => {
                        setDrawerHtml(secondLastQuestion.html)
                        setDrawer(true)
                      }}>
                      {secondLastQuestion.subhead && <Kicker>{secondLastQuestion.subhead}</Kicker>}
                      {secondLastQuestion.heading}
                    </Subhead>
                    <Link 
                      onClick={() => {
                        setDrawerHtml(secondLastQuestion.html)
                        setDrawer(true)
                      }}>
                      <Text>More information....</Text>
                    </Link>
                  </Box>
                </Flex>
              </Container>
            </Section>
          }
          <Section padding={4}>
            <Container>
              <Flex gap={4} variant="responsive">
                <Box width="full" order={1}>
                  <Subhead 
                    onClick={() => {
                      setDrawerHtml(lastQuestion.html)
                      setDrawer(true)
                    }}>
                    {lastQuestion.subhead && <Kicker>{lastQuestion.subhead}</Kicker>}
                    {lastQuestion.heading}
                  </Subhead>
                  <Link 
                    onClick={() => {
                      setDrawerHtml(lastQuestion.html)
                      setDrawer(true)
                    }}>
                    <Text>More information....</Text>
                  </Link>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.html,
                    }} />
                </Box>
              </Flex>
            </Container>
          </Section>
          <Section padding={4}>
            <Container>
              <Flex gap={4} variant="responsive">
                <Box width="full" order={1}>
                  <CheckoutButton product={product} />
                </Box>
              </Flex>
            </Container>
          </Section>
        </Box>
      <Drawer open={drawer} toggleDrawer={toggleDrawer} html={drawerHtml} />
    </Container>
  )
}

export const query = graphql`
  fragment HomepageProductConfigContent on HomepageProductConfig {
    id
    heading
    subhead
    content {
      id
      heading
      subhead
      html
      type
      content {
        id
        heading
        subhead
        image {
          alt
          id
          gatsbyImageData
        }
        html
      }
    }
    questions {
      id
      heading
      subhead
    }
    html
  }
`