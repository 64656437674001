import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
  List
} from "../ui"

export default function CheckboxLabels({ booleanQuestions, product, setProduct }) {
  const handleChange = (event, heading) => {
    setProduct({...product, [heading]: event.target.checked})
  };

  return (
    <FormGroup>
      {booleanQuestions.map(question => (
        <FormControlLabel 
          key={question.id}
          control={
            <Checkbox 
              checked={product[question.heading]}
              onChange={(event) => handleChange(event, question.heading)} />
          }   
          label={<List>{question.subhead}</List>} />
      ))}
    </FormGroup>
  );
}