import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
  Flex,
  Box,
  Subhead,
  Text,
  Kicker,
  Link,
} from "./ui"

import ConfigButtons from './material/toggleButtons'
import Drawer from "./material/informationDrawer"

export default function Feature(props) {
  const [drawer, setDrawer] = React.useState(false);

  const toggleDrawer = open => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  return (
    <Section padding={4}>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="half" order={props.flip ? 1 : null}>
            <ConfigButtons
              type={props.type}
              options={props.content} 
              setProduct={props.setProduct} 
              product={props.product} />
          </Box>
          <Box width="half">
            <Subhead onClick={() => setDrawer(true)}>
              {props.subhead && <Kicker>{props.subhead}</Kicker>}
              {props.heading}
            </Subhead>
            <Link onClick={() => setDrawer(true)}>
              <Text>More information....</Text>
            </Link>
          </Box>
        </Flex>
      </Container>
      <Drawer open={drawer} toggleDrawer={toggleDrawer} html={props.html} />
    </Section>
  )
}

export const query = graphql`
  fragment HomepageProductConfigQuestion on HomepageProductConfigQuestion {
    id
    heading
    subhead
    html
    type
  }
`
