import * as React from 'react';
import Grid from '@mui/material/Grid';

import DemoCard from './demoCard'

export default function TemplateCards({ content }) {
  return (
    <Grid 
      container 
      rowSpacing={{ xs: 1, md: 2, lg: 3 }} 
      columnSpacing={{ xs: 1, md: 2, lg: 3 }}
      alignItems="stretch">
      {content.map(template => (
        <Grid key={template.id} item lg={4} md={6} sm={12}>
          <DemoCard card={template} />
        </Grid>
      ))}
    </Grid>
  );
}
